<template>
  <div class="blog-page">
    <div v-loading="blogListLoading" class="blog-list">
      <div v-if="blogList.length">
        <article :key="blog.source_name" v-for="blog in paginatedData" class="blog-item">
          <router-link :to="`/blog/${blog.source_name}`" target="_blank">
            <!-- 添加封面图片 -->
            <img v-if="blog.cover_image_name" :src="blog.cover_image_name" :alt="blog.cover_image_name"
                 class="cover-image"/>
            <h2 class="article-headline">{{ blog.title }}</h2>
          </router-link>
          <div class="blog-content">
            <span v-html="truncateText(blog.content)"></span>
            <router-link :to="`/blog/${blog.source_name}`" class="read-more" v-if="shouldShowReadMore(blog.content)">
              <span class="read-more-text">Read More »</span>
            </router-link>
          </div>
        </article>
      </div>
      <div v-else class="no-article">
        There are no articles in this category or tag!
      </div>
      <div class="pagination-container">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="blogList.length * 2"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <div class="sidebar">
      <div class="sidebar-item">
        <h3>Categories</h3>
        <el-table v-loading="cateListLoading"
                  :show-header="false"
                  :data="categories"
                  @row-click="handleCateClick"
                  :row-class-name="tableCateRowClassName">
          <el-table-column fixed prop="name"/>
          <el-table-column fixed prop="amount"/>
        </el-table>
      </div>
      <div class="sidebar-item">
        <h3>Tag</h3>
        <el-table v-loading="tagListLoading"
                  :show-header="false"
                  :data="tags"
                  @row-click="handleTagClick"
                  :row-class-name="tableTagRowClassName">
          <el-table-column fixed prop="name"/>
          <el-table-column fixed prop="amount"/>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {post, get} from '../api'

export default {
  name: 'Home',
  data() {
    return {
      originBlogList: [],
      blogListLoading: true,
      cateListLoading: true,
      tagListLoading: true,
      categories: [],
      tags: [],
      blogList: [],
      currentPage: 1,
      pageSize: 5, // 每页显示的条目数
      selectedCateRowIndex: null, // 选中cate的行的索引
      selectedCate: null,
      selectedTagRowIndex: null, // 选中tag的行的索引
      selectedTag: null,
      // websitePrefix: 'http://taocoweb.top/static/image/'
    }
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.blogList.slice(start, end);
    }
  },
  created() {
    post('/blog/home-list').then(res => {
      this.blogListLoading = false
      this.originBlogList = res.data
      this.blogList = res.data
    })

    get('/blog/dynamicCates/list').then(res => {
      this.cateListLoading = false
      this.categories = res.data
    })

    get('/blog/dynamicTags/list').then(res => {
      this.tagListLoading = false
      this.tags = res.data
    })
  },
  methods: {
    truncateText(text) {
      const maxLength = 100;
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    },
    shouldShowReadMore(text) {
      const maxLength = 100;
      return text.length > maxLength;
    },
    handleCurrentChange(page) {
      this.currentPage = page;

      // 自动滚动到页面顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth',  // 平滑滚动
      });
    },
    handleCateClick(row) {
      // 获取点击的行索引
      if (this.selectedCateRowIndex != null
          && this.selectedCateRowIndex === this.categories.indexOf(row)) {
        this.selectedCateRowIndex = null;
        this.selectedCate = null;
      } else {
        this.selectedCateRowIndex = this.categories.indexOf(row);
        this.selectedCate = row.name;
      }
      this.flushBlogList()
    },
    handleTagClick(row) {
      if (this.selectedTagRowIndex != null
          && this.selectedTagRowIndex === this.tags.indexOf(row)) {
        this.selectedTagRowIndex = null;
        this.selectedTag = null;
      } else {
        this.selectedTagRowIndex = this.tags.indexOf(row);
        this.selectedTag = row.name;
      }
      this.flushBlogList()
    },
    flushBlogList() {
      if (this.selectedCate && this.selectedTag) {
        this.blogList = this.originBlogList
            .filter(item => item.dynamic_cates.includes(this.selectedCate)
                && item.dynamic_tags.includes(this.selectedTag));
      } else if (this.selectedCate) {
        this.blogList = this.originBlogList
            .filter(item => item.dynamic_cates.includes(this.selectedCate));
      } else if (this.selectedTag) {
        this.blogList = this.originBlogList
            .filter(item => item.dynamic_tags.includes(this.selectedTag));
      } else {
        this.blogList = this.originBlogList;
      }
    },
    tableCateRowClassName({rowIndex}) {
      // 如果当前行是选中的行，返回一个特殊的类名
      if (rowIndex === this.selectedCateRowIndex) {
        return 'selected-row';
      }
      return '';
    },
    tableTagRowClassName({rowIndex}) {
      // 如果当前行是选中的行，返回一个特殊的类名
      if (rowIndex === this.selectedTagRowIndex) {
        return 'selected-row';
      }
      return '';
    }
  },
}
</script>

<style>
.blog-page {
  display: flex;
  justify-content: space-between;
  /* 将子元素分散排列 */
  align-items: flex-start;
  /* 垂直居上对齐 */
}

.blog-list {
  margin-left: 10%;
  width: 55%;
}

.blog-item {
  border: 1px solid #ccc;
  margin: 24px 0;
  /* 上下外边距控制博客项之间的间距 */
}

.article-headline {
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 22px;
}

.blog-content {
  margin-left: 10px;
  margin-bottom: 10px;
}

.read-more {
  color: rgb(43, 109, 216);
}

.read-more-text {
  white-space: nowrap;
  /* 防止文本换行 */
}

.sidebar {
  margin-top: 5%;
  margin-right: 5%;
  width: 25%;
}

.sidebar-item {
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
}

.sidebar-item h3 {
  margin-top: 0;
}

.cover-image {
  width: 100%;
  max-height: 300px;
  /* 设置最大高度，防止图片过大 */
  object-fit: cover;
  /* 保持图片比例并裁剪以适应容器 */
}

.pagination-container {
  display: flex;
  justify-content: center; /* 居中对齐 */
  margin-top: 20px; /* 可选，增加与表格的间距 */
}

/* 定义选中行的样式 */
.selected-row {
  color: rgb(43, 109, 216); /* 你也可以更改字体颜色，使之与背景色对比鲜明 */
  font-weight: bold;
}

.no-article {
  font-family: 'Pacifico', cursive; /* 引入Pacifico字体，你可以换成其他字体 */
  font-size: 24px;
  margin-top: 20px;
  color: rgb(43, 109, 216);
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: shake 0.5s infinite;
}
</style>